/* Import custom fonts from Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");

/* Global styles */
body {
  background-color: rgb(0, 0, 0);
  font-size: 16px;
  line-height: 1.5;
  font-family: 'Roboto Mono', monospace; /* Updated font-family */
}

.App {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-family: 'VT323', monospace;
  margin-top: 5px; /* Add this line to create space for the NavBar */
}

/* Set placeholder text color to gray for input elements */
input[type="text"]::placeholder {
  color: gray;
}

/* Network canvas styles */
.network-canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Overlay styles */
.overlay {
  opacity: 0.85;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
}

/* Navbar styles */
.nav-bar {
  background-color: #4a4949;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 2px 5px rgb(0, 0, 0);
}

/* Button styles */
.custom-button {
  background-color: #00FF00;
  color: black;
  border-radius: 5px;
  box-shadow: 0px 2px 2px 1px #0F0F0F;
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  padding: 15px;
  margin-left: 10px;
  font-size: 16px; /* Increase font size for buttons */
}

.custom-button:hover {
  /* Define styles for button hover state if needed */
}

/* Lime green titles */
.title {
  color: limegreen;
  font-size: 16px; /* Increase font size for titles */
  margin-bottom: 20px;
}

/* Add this CSS to style the 3x3 grid */
.xmap-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.xmap-item {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

/* Add any additional styles as needed */